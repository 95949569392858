import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img, { FluidObject } from "gatsby-image";

type Data = {
  data: {
    placeholderImage: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
  };
};

/** Refer to https://www.gatsbyjs.org/packages/gatsby-image/#gatsby-image-props for props */
export const PureAppPreviewImage: React.FC<Data> = ({ data, ...attrs }) => {
  return (
    <Img
      fluid={data.placeholderImage.childImageSharp.fluid}
      alt="An image of an AppPreview"
      className="absolute inset-0 w-full h-full object-cover"
      {...attrs}
    />
  );
};

const AppPreviewImage: React.FC = ({ ...attrs }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "app-preview.png" }) {
        childImageSharp {
          fluid(maxWidth: 2002) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return <PureAppPreviewImage data={data} {...attrs} />;
};

export default AppPreviewImage;
